<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import './DataObject.PagedData.ts';
import Paginator from './Paginator.vue';
import { isMobile } from "o365.GlobalState.ts";

/*
    SLOTS
    - default for setting up what each row of the list should contain
    - heading for setting the list heading
    - actions for setting the action/button section to the right of heading
*/

export interface IProps {
    dataObject: DataObject,
    /* Classes string for the list container */
    listClass?: string,
    /* Don't show the header at all */
    disableHeader?: boolean,
    /* Move the pagination controls beside the title (can be overwritten by actions) */
    showPaginationInHeader?: boolean,
    /* Hide the border that appears under header text */
    hideHeaderBorder?: boolean,
    /* Disable dropdown that lets the user adjust list page size */
    disablePageSizeDropdown?: boolean,
}

const props = defineProps<IProps>();

if (!isMobile.value) {
    props.dataObject.pagedData.enable();
}
    // props.dataObject.load();


async function showMore() {
    window.dataObject = props.dataObject;
    await props.dataObject.loadNextPage({ timeout: 30000 });
}
</script>

<template>
    <div>
        <h5 v-if="!disableHeader" class="d-flex flex-wrap justify-content-between text-muted  gap-2 mb-0" :class="{'border-bottom':!hideHeaderBorder}" style="min-height:32px;">
            <slot name="heading"></slot>

            <slot name="actions">
                <template v-if="showPaginationInHeader">
                    <span class="fw-normal" style="font-size:initial;">
                        <Paginator :dataObject="dataObject" :disablePageSizeDropdown="disablePageSizeDropdown"></Paginator>
                    </span>
                </template>
            </slot>
        </h5>
        
        <div class="list-unstyled w-100 mb-0" :class="listClass">
            <div v-for="row in dataObject.data">
                <slot :row="row"></slot>
            </div>
        </div>

        <template v-if="isMobile">
            <template v-if="!dataObject.dynamicLoading.lastPageReached">
                <div class="d-flex justify-content-end" style="margin-bottom: -1rem;">
                    <a class="btn btn-link" @click="showMore()" :disabled="dataObject.state.isNextPageLoading">
                        <template v-if="dataObject.state.isNextPageLoading">
                            <div class="spinner-border spinner-border-sm" />
                        </template>
                        <template v-else>
                            {{ $t("Show more") }}
                        </template>
                    </a>
                </div>
            </template>
        </template>
        <template v-else>
            <template v-if="!showPaginationInHeader">
                <Paginator :dataObject="dataObject" :disablePageSizeDropdown="disablePageSizeDropdown ? true : false"></Paginator>
            </template>
        </template>
    </div>
</template>